import { Grid, Hidden } from "@material-ui/core";
import React from "react";

export default function Ingredients() {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={4}
      lg={4}
      id="ingredients"
      data-aos="flip-right"
      data-aos-offset="500"
      data-aos-delay="50"
      data-aos-duration="2000"
      data-aos-easing="ease-out-cubic"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-bottom"
    >
      <section
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-out-cubic"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-bottom"
      >
        {/* desktop */}
        <Hidden smDown>
          <h3>Prepared fresh</h3>
          <h3> with quality</h3>
          <h3>ingredients from</h3>
          <h3>our trusted </h3>
          <h3>partners.</h3>
        </Hidden>

        {/* mobile */}
        <Hidden mdUp>
          <h3>We are building a healthier </h3>
          <h3>community by crafting </h3>
          <h3>healthy fresh foods made </h3>
          <h3>from handpicked quality </h3>
          <h3>ingredients from local </h3>
          <h3>farmers</h3>
        </Hidden>

        <img
          src="https://res.cloudinary.com/dsepncuj8/image/upload/v1656585106/Figma%20Wbsite%20Images/132456_vxzoei.png"
          alt="Ingredients"
        />
      </section>
    </Grid>
  );
}
