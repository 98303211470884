import { Grid, Hidden } from "@material-ui/core";
import React from "react";
import AppBtns from "./GetApp/AppBtns";
import Options from "./GetApp/Options";

export default function GetApp() {
  return (
    <>
      <Grid
        container
        id="get_app_v2"
        data-aos="fade-down"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
        data-aos-anchor-placement="top-bottom"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={7}
          id="get_app_content"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-bottom"
        >
          <h3>THE APP IS ALL YOU NEED</h3>

          {/* mobile */}
          <Hidden mdUp>
            <section>
              <h1>Diverse categories of meals. Say what you </h1>
              <h1>what and how you want it prepared!</h1>
            </section>
          </Hidden>

          {/* desktop */}
          <Hidden smDown>
            <section>
              <h1>Diverse categories of meals.</h1>
              <h1>Say what you what and how you </h1>
              <h1>want it prepared!</h1>
            </section>

            <Options />

            <AppBtns />
          </Hidden>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          id="mobile_phone"
          data-aos="flip-left"
          data-aos-offset="500"
          data-aos-delay="50"
          data-aos-duration="2000"
          data-aos-easing="ease-out-cubic"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <img
            src="https://res.cloudinary.com/dsepncuj8/image/upload/v1648193110/Figma%20Wbsite%20Images/Mobile_app_MOCKUP_reqik5.png"
            alt="mobile phone"
          />
        </Grid>

        {/* mobile */}
        <Hidden mdUp>
          <Options />

          <AppBtns />
        </Hidden>
      </Grid>
    </>
  );
}
