import React from "react";
import Content from "./Content";

export default function Newsletter() {
  return (
    <>
      <Content heading={"Sign up for our newsletters"} isForm={true} />
    </>
  );
}
