import React from "react";

export default function TermsOfService() {
  return (
    <div id="T_and_C">
      <h1>Terms of Service</h1>

      <iframe
        title="Terms of use"
        src="https://docs.google.com/document/d/e/2PACX-1vQ0H-rOAg42JozzbuJmK8vsdm_pf2g5Sm0FEniQxHntaai4rLUcnZ6OuDXiFAeyfQ/pub?embedded=true"
      ></iframe>
    </div>
  );
}
