import { Grid, Hidden } from "@material-ui/core";
import React from "react";

export default function Kitchen() {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={4}
      lg={4}
      id="kitchen"
      data-aos="flip-right"
      data-aos-offset="500"
      data-aos-delay="50"
      data-aos-duration="2000"
      data-aos-easing="ease-out-cubic"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-bottom"
    >
      <Hidden smDown>
        <h1>KITCHEN</h1>
        <h1>HYGIENE</h1>

        <section
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-out-cubic"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <h3> We adopt</h3>
          <h3>standard hygiene</h3>
          <h3>and safety</h3>
          <h3>practices at every</h3>
          <h3>stage of our food</h3>
          <h3>preparation</h3>
          <h3>process.</h3>
        </section>
      </Hidden>

      <Hidden mdUp>
        <h1>KITCHEN HYGIENE</h1>

        <section
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-out-cubic"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <h3> We adopt standard hygiene and </h3>
          <h3>safety practices at every stage of our </h3>
          <h3>food preparation process.</h3>
        </section>
      </Hidden>
    </Grid>
  );
}
