import { Grid } from "@material-ui/core";
import React from "react";

export default function AppBtns() {
  return (
    <>
      <Grid container id="btns">
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <a href="https://play.google.com/store/apps/details?id=com.oma1">
            <img
              src="https://res.cloudinary.com/dsepncuj8/image/upload/v1656585113/Figma%20Wbsite%20Images/Android_zik1og.png"
              alt="Google Play Store"
            />
          </a>
        </Grid>

        <Grid item xs={6} sm={6} md={5} lg={5}>
          <a href="https://apps.apple.com/ng/app/ooma-meal-plan-family-chef/id1598303017">
            <img
              src="https://res.cloudinary.com/dsepncuj8/image/upload/v1656585097/Figma%20Wbsite%20Images/apple_eenjx1.png"
              alt="Google Play Store"
            />
          </a>
        </Grid>
      </Grid>
    </>
  );
}
