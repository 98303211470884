import React from "react";
import Content from "./Content";

export default function TermsOfUse() {
  return (
    <>
      <Content
        heading={"Agreements"}
        title1={"Terms of service"}
        title2={"Privacy policy"}
        link1="/terms-of-use"
        link2="/privacy-policy"
      />
    </>
  );
}
