import { Hidden } from "@material-ui/core";
import React from "react";
import { Carousel } from "react-responsive-carousel";

export default function Hero() {
  return (
    <section
      id="hero"
      data-aos="zoom-in"
      data-aos-offset="500"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="top-bottom"
    >
      <img
        src="https://res.cloudinary.com/dsepncuj8/image/upload/v1648193107/Figma%20Wbsite%20Images/Your_digital_Family_Chef_fbg8gw.png"
        alt="intro shape"
      />
      <p>... YOUR DIGITAL FAMILY CHEF</p>

      <Carousel
        autoPlay="true"
        infiniteLoop="true"
        showThumbs="false"
        showStatus="false"
        stopOnHover="true"
        useKeyboardArrows="true"
        swipeable="true"
        emulateTouch="true"
      >
        <Content1 />
        <Content2 />
        <Content3 />
      </Carousel>
    </section>
  );
}

function Content1() {
  return (
    <>
      <Hidden smDown>
        <aside>
          <h1>SUPER </h1>
          <h1>
            <span>CONVENIENT</span>
          </h1>
        </aside>
      </Hidden>

      <Hidden mdUp>
        <aside>
          <h1>
            <span>PROFESSIONAL</span>
          </h1>
          <h1>CHEF</h1>
          <h1>SERVICE</h1>
        </aside>
      </Hidden>
    </>
  );
}

function Content2() {
  return (
    <>
      <Hidden smDown>
        <aside>
          <h1>VARIETIES OF HEALTHY</h1>
          <h1>
            <span>GOOD-FOR-YOU</span> MEALS
          </h1>
        </aside>
      </Hidden>

      <Hidden mdUp>
        <aside>
          <h1>VARIETIES OF</h1>
          <h1>HEALTHY</h1>
          <h1>
            <span>GOOD-FOR-YOU</span>
          </h1>
          <h1>MEALS</h1>
        </aside>
      </Hidden>
    </>
  );
}

function Content3() {
  return (
    <>
      <Hidden smDown>
        <aside>
          <h1>
            <span>QUALITY INGREDIENT</span>
          </h1>
          <h1>SOURCED FROM</h1>
          <h1>
            <span>TRUSTED</span> PARTNERS
          </h1>
        </aside>
      </Hidden>

      <Hidden mdUp>
        <aside>
          <h1>
            <span>QUALITY</span>
          </h1>
          <h1>
            <span>INGREDIENT</span>
          </h1>
          <h1>SOURCED FROM</h1>
          <h1>
            <span>TRUSTED</span>
          </h1>
          <h1>PARTNERS</h1>
        </aside>
      </Hidden>
    </>
  );
}
