import React from "react";
import Content from "./Content";

export default function Resources() {
  return (
    <>
      <Content
        heading={"Resources"}
        title1={"Home"}
        link1="/"
      />
    </>
  );
}
