import React from "react";
import Hero from "./HeroAndNav/Hero";
import NavV2 from "./HeroAndNav/NavV2";

export default function HeroAndNav() {
  return (
    <>
      <section
        id="hero_nav"
        data-aos="fade-down"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
        data-aos-anchor-placement="top-bottom"
      >
        <NavV2 />
        <Hero />
      </section>
    </>
  );
}
