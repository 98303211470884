import { Grid, Hidden } from "@material-ui/core";
import React from "react";

export default function Healthy() {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={4}
      lg={4}
      id="healthy"
      data-aos="flip-right"
      data-aos-offset="500"
      data-aos-delay="50"
      data-aos-duration="2000"
      data-aos-easing="ease-out-cubic"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-bottom"
    >
      {/* desktop */}
      <Hidden smDown>
        <h1>HEALTHY FOOD</h1>

        <section
          data-aos="fade-up"
          data-aos-offset="500"
          data-aos-delay="50"
          data-aos-duration="2000"
          data-aos-easing="ease-out-cubic"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <h3>Enjoy quality food </h3>
          <h3>that provides the </h3>
          <h3>needed</h3>
          <h3>nourishment for</h3>
          <h3>healthy living.</h3>
        </section>
      </Hidden>

      {/* mobile */}
      <Hidden mdUp>
        <img
          src="https://res.cloudinary.com/dsepncuj8/image/upload/v1655880407/Figma%20Wbsite%20Images/RECENT%20WEB%20SITE%20IMAGES/Logo_mask_vyclu5.png"
          alt="ooma_logo"
        />

        <section
          data-aos="fade-up"
          data-aos-offset="500"
          data-aos-delay="50"
          data-aos-duration="2000"
          data-aos-easing="ease-out-cubic"
          data-aos-mirror="true"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <h3>ooma kitchen is a digital family chef that </h3>
          <h3>offers meal planning and a wide range of </h3>
          <h3>high quality cuisine options </h3>
        </section>
      </Hidden>
    </Grid>
  );
}
