import React from "react";

export default function PrivacyPolicy() {
  return (
    <div id="T_and_C">
      <h1>Privacy Policy</h1>

      <iframe
        title="Privacy Policy"
        src="https://docs.google.com/document/d/e/2PACX-1vQNmQtQ46Qfb7OmgWZ3tUQ_Ad3Ickz-FpP5uq8W-AfjnMFv0YlvAi9wjPUIyNuNYg/pub?embedded=true"
      ></iframe>
    </div>
  );
}
